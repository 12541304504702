"use client"

import getURLParameter from "src/lib/client/getURLParameter";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import { useSelectedCinemaContext } from "src/lib/contexts/SelectedCinemaContext";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

//#region [Other]
export type AdContextType = {
    kv?: object[];
    //refresh: () => void;
    setKv: (newKv: any) => void;
    loadedAds: string[];
    noMatchedAds: string[];
}

const AdContext = createContext<AdContextType>({
    kv: undefined,
    //refresh: () => { },
    setKv: (newKv: any) => { },
    loadedAds: [],
    noMatchedAds: []
});

// https://docs.adnuntius.com/adnuntius-advertising/requesting-ads/intro/adn-request
type FloorPrice = {
    amount: number;
    currency: string;
}

export type KV = {
    [key: string]: string[];
}

export type AdUnit = {
    auId: string;
    auW?: string | number | null;
    auH?: string | number | null;
    targetId?: string | null;
    requestMode?: "default" | "hasTarget" | null;
    protocol?: "http" | "https" | null;
    requestTiming?: "immediate" | "onReady" | "onLoad" | null;
    display?: string | null; // css display value.
    container?: "iframe" | "div" | null;
    ps?: number | null;
    collapsible?: boolean | null;
    resizeOnPageLoad?: boolean | null;
    useCookies?: boolean | null;
    floorPrice?: FloorPrice | null;
    clearTarget?: boolean | null;
    kv?: KV[] | null;

    onResponse?: ((...args: any[]) => void) | null;
    onAllResponses?: ((...args: any[]) => void) | null;
    onImpressionResponse?: ((...args: any[]) => void) | null;
    onPageLoad?: ((...args: any[]) => void) | null;
    onNoMatchedAds?: ((...args: any[]) => void) | null;
    onVisible?: ((...args: any[]) => void) | null;
    onViewable?: ((...args: any[]) => void) | null;
    onError?: ((...args: any[]) => void) | null;

};

//#endregion

//#region [Props]
type LOADING_IMMEDIATE = "immediate";
type LOADING_CONTENT = "content";

type AdWrapperProps = {
    kv?: KV[];
    children: React.ReactNode;
    ads: AdUnit[];
    defaultProximity?: number;
};
//#endregion



//#region [Component]
export default function AdWrapper({ kv, children, ads, defaultProximity = 0 }: AdWrapperProps) {
    const locationContext = useLocationContext();
    const cinemaContext = useSelectedCinemaContext();

    const [_kv, setKv] = useState(kv);
    const [loadedAds, setLoadedAds] = useState<string[]>([]);
    const [noMatchedAds, setNotMatchedAds] = useState<string[]>([]);
    const [hasRequestedAds, setHasRequestedAds] = useState(false);
    //const [_refreshTime, setRefreshTime] = useState(Date.now());
    //console.debug("Refresh time", _refreshTime);
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
            console.debug("%cClearing out", "color: white; background: indigo");
            window?.adn?.clearOut?.();
        };

        router.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        }
    }, [router]);

    useEffect(() => {
        if ((ads?.length ?? 0) > 0) {
            const skipAds = getURLParameter("skipAds");
            if (skipAds === "true") {
                return;
            }
            const __kv = _kv ? [..._kv] : [];
            const test = getURLParameter("test");
            if (test) {
                __kv.push({ "test": [test as string] });
            }

            if (typeof window !== "undefined") {
                try {
                    if (window.location.pathname) {
                        __kv.push({ "pathname": [window.location.pathname] });
                    }
                    if (window.location.search) {
                        __kv.push({ "search": [window.location.search] });
                    }
                } catch (e) {
                    console.error("Error setting KV");
                }
                console.debug("%cIs location ready?", "color: white; background: indigo", locationContext.isReady,);
                console.debug("%cIs cinema ready?", "color: white; background: indigo", cinemaContext.isReady);
                console.debug("%cHas already requested ads?", "color: white; background: indigo", hasRequestedAds);
                // add cinema location to the ad if the user has chosen a location
                if (locationContext.isReady !== false) {
                    __kv.push({ "location": [locationContext.location ?? ""] });
                } else {
                    console.debug("%cNot requesting ad because location is not initialized", "color: white; background: indigo");
                    return;
                }

                // add cinema to the ad if the user is only showing a subset of available cinemas
                if (cinemaContext.isReady !== false) {
                    if (cinemaContext.selectedCinemas) {
                        __kv.push({ "cinema": cinemaContext.selectedCinemas });
                    }
                } else {
                    console.debug("%cNot requesting ad because selected cinema is not initialized", "color: white; background: indigo", hasRequestedAds);
                    return;
                }

                if( hasRequestedAds ) {
                    console.debug("%cNot requesting ad because ads have already been requested", "color: white; background: indigo", hasRequestedAds);
                    return;
                }

                const adPayload = {
                    useCookies: false,
                    adUnits: ads.map(ad => {
                        // merge individual ad units kv with "global" kv
                        const _ad = { ...ad };
                        if (_ad.kv) {
                            _ad.kv = { ..._ad.kv, ...__kv };
                        }
                        if (!_ad.targetId) {
                            // make sure we have a target Id
                            _ad.targetId = `adn-${_ad.auId}`
                        }
                        return _ad;
                    }),
                    kv: __kv,
                    requestParams: {
                        proximity: defaultProximity,
                        load: "lazyRequest"
                    },
                    clearTarget: true,
                    requestMode: "default", // default | hasTarget
                    onPageLoad: function (adUnit: AdUnit) {
                        console.debug("%cLoaded ad", "color: white; background: indigo", adUnit);
                        /*
                        if (!loadedAds.includes(adUnit.targetId!)) {
                            console.debug("Loaded ad", adUnit);
                            //setLoadedAds((loaded) => [...loaded, adUnit.targetId]);
                        }*/
                    },
                    onNoMatchedAds: function (adUnit: AdUnit) {
                        console.debug("%cNo matched ads", "color: white; background: indigo", adUnit); // arguments is language feature

                        if (!noMatchedAds.includes(adUnit.targetId!)) {
                            setNotMatchedAds([...noMatchedAds, adUnit.targetId!]);
                        }
                    }/*,
					onVisible: function( args: object ) {
						console.debug("On visible", args);
					},
					onViewable: function( args: object ) {
						console.debug("On Viewable", args);
					}*/
                };

                // make sure the adn object is defined
                if (!window.adn || !window.adn.calls) {
                    console.debug("%cReplacing adn", "color: white; background: indigo", window.adn, window.adn?.calls)
                    window.adn = window.adn ?? {};
                    window.adn.calls = window.adn.calls ?? [];
                }
                setHasRequestedAds(true);
                window.adn.calls.push(() => {
                    console.debug("%cMarketing consent status", "color: white; background: indigo", !!window?.Cookiebot?.consent?.marketing);
                    adPayload.useCookies = !!window?.Cookiebot?.consent?.marketing;
                    window.adn.useLocalStorage(!!window?.Cookiebot?.consent?.marketing);
                    console.debug("%cRequesting ad", "color: white; background: indigo", adPayload, "KV", _kv, "Location", locationContext.location, "Selected cinemas", cinemaContext.selectedCinemas, "proximity", defaultProximity, "path", router.asPath);
                    window.adn.requestAds(adPayload);
                });

            }
        };


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ads, _kv, locationContext.location, locationContext.isReady, cinemaContext.selectedCinemas, cinemaContext.isReady, /*_refreshTime,*/ defaultProximity, router.asPath]);


    return <AdContext.Provider value={
        {
            loadedAds,
            noMatchedAds,
            kv: _kv,
            setKv,
            //refresh: () => setRefreshTime(Date.now())
        }
    }>
        {children}
    </AdContext.Provider>;
}
//#endregion

//#region [Other]
export function useAdContext() {
    return useContext(AdContext);
}
//#endregion