import Button from "src/components/button/Button";
import SelectorWrapper from "src/components/selectorwrapper/SelectorWrapper";
import { useSelectedCinemaContext } from "src/lib/contexts/SelectedCinemaContext";
import styled from 'src/lib/styles/css';

//#region [Props]
type CinemaSelectorProps = {
};
//#endregion

//#region [Component]
export default function CinemaSelector({ }: CinemaSelectorProps) {
	const cinemaContext = useSelectedCinemaContext();
	return <SelectorWrapper onClose={() => cinemaContext.setShowSelectCinemaDialog(false)} heading="Velg kino">
		<div>
			<SResultArea>
				<SCinemaButton
					text="Alle kinoer"
					onClick={() => { cinemaContext.setSelectedCinemas(null); cinemaContext.setShowSelectCinemaDialog(false); }}
					isOutline
				/>
				{cinemaContext.availableCinemas?.slice(0, 20)?.map(cinema => <SCinemaButton
					key={`${cinema.firmId}_${cinema.name}`}
					text={cinema.name}
					onClick={() => { cinemaContext.setSelectedCinemas([cinema.name]); cinemaContext.setShowSelectCinemaDialog(false); }}
					isOutline
				/>)}
			</SResultArea>
		</div>
	</SelectorWrapper>;
}
//#endregion

//#region [Styles]
const SResultArea = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0 30px;
	gap: 10px;
`;

const SCinemaButton = styled(Button)`
	border: 2px var(--TEXTCOLOR_OVERRIDE, var(--iconcolor)) solid;
	-moz-outline-radius: 100px;
	font-size: 18px;
	font-weight: 500;

	&:hover {
		background-color: var(--TEXTCOLOR_OVERRIDE, var(--iconcolor));
		color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--backgroundcolor));
	}
`;

//#endregion
